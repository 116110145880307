<style scoped lang="less">
  .platform {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    .item {
      flex: 1;
      border: 1px dashed #EEE;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px;
      cursor: pointer;
      &, span {
        transition: all .3s;
      }
      &.active, &:hover {
        border-color: #2d8cf0;
        span {
          color: #2d8cf0;
        }
      }
      &.active {
        border-style: solid;
      }
    }
    .iconfont { font-size: 40px; }
    .icon-anzhuo1 {
      color: #85c907;
    }
    .icon-pingguo {
      color: #696969;
    }
    .item + .item {
      margin-left: 10px;
    }
  }
  img.qrcode {
    width: 35vh;
    min-width: 200px;
    display: block;
    margin: 0 auto;
  }
  .redtext {
    font-weight: bold;
    color: red;
  }
  .platform-details {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    & + * {
      margin-top: 10px;
    }
    .link {
      display: block;
      white-space: pre-wrap;
      word-break: break-all;
    }
    .link-btn {
      margin-top: 10px;
      & > * + * {
        margin-left: 10px;
      }
    }
  }
</style>

<template>
  <div>
    <Alert v-if="error.show" type="error" show-icon>
      {{error.title}}
      <span slot="desc">{{error.desc}}</span>
    </Alert>
    <template v-if="data">
      <h1 style="margin-bottom: 10px;text-align: center;">一远智慧医疗APP安装引导</h1>
      <Alert class="ivu-alert-with-desc" type="info" show-icon closable>
        请根据自己所使用手机品牌选择安装
        <div slot="desc">
          <div>目前一远智慧医疗APP支持安卓（Android系统）、iPhone（iOS系统）两类设备</div>
          <div>小米、华为、OPPO、ViVO等品牌手机请选择安卓</div>
        </div>
      </Alert>

      <div class="platform">
        <div class="item" :class="{active: platform === 'android'}" @click="platform = 'android'">
          <i class="iconfont icon-anzhuo1"></i>
          <span>安卓（Android系统）</span>
          <div v-if="data.android">
            <span>v{{data.android.code}}</span>
            <span v-if="data.android.wgt">（{{data.android.wgt.code}}）</span>
          </div>
          <span v-else>暂无可用版本</span>
        </div>
        <div class="item" :class="{active: platform === 'ios'}" @click="platform = 'ios'">
          <i class="iconfont icon-pingguo"></i>
          <span>iPhone（iOS系统）</span>
          <div v-if="data.ios">
            <span>v{{data.ios.code}}</span>
            <span v-if="data.ios.wgt">（{{data.ios.wgt.code}}）</span>
          </div>
          <span v-else>暂无可用版本</span>
        </div>
      </div>

      <template v-if="platform">
        <template v-if="data[platform]">
          <div class="platform-details" v-if="platform === 'ios'">
            <Alert class="ivu-alert-with-desc" type="info" show-icon>
              请使用相机、微信扫一扫（选择在浏览器中打开）、浏览器<span class="redtext">扫一扫下方二维码下载</span>
              <div slot="desc">
                扫码后将跳转至<span class="redtext">App Store</span>，请点击<span class="redtext">安装</span>或<span class="redtext">下载</span>按钮进行安装
              </div>
            </Alert>
            <img class="qrcode" :src="qrcode.ios" />
            <a class="link" :href="data.ios.file">{{data.ios.file}}</a>
            <div class="link-btn">
              <Button type="primary" @click="copy(data.ios.file)">复制链接到手机浏览器中打开</Button>
            </div>
          </div>
          <div class="platform-details" v-else>
            <Alert class="ivu-alert-with-desc" type="info" show-icon>
              请使用相机、微信扫一扫（选择在浏览器中打开）、浏览器<span class="redtext">扫描下方二维码下载</span>
              <div slot="desc">
                扫码后将跳转至安装包下载链接，请在下载完成后安装APP。
              </div>
            </Alert>
            <img class="qrcode" :src="qrcode.android" />
            <a class="link" :href="data.android.file">{{data.android.file}}</a>
            <div class="link-btn">
              <Button type="primary" @click="copy(data.android.file)">复制链接到手机浏览器中打开</Button>
            </div>
          </div>
          <Alert type="warning" class="ivu-alert-with-desc" show-icon>
            第一次安装请根据APP提示，使用APP<span class="redtext">扫描下方二维码激活</span>
            <div slot="desc">
              <div>1. 如有异常，请检查手机APP设置，是否授予网络、相册、存储使用权限。</div>
              <div>2. 确定APP权限授权后，请关闭APP后重新打开。</div>
              <div>3. 卸载重装后会丢失激活信息，需要重新激活</div>
            </div>
          </Alert>
          <img class="qrcode" :src="qrcode.activation" />
          <Button type="primary" @click="print" long>打印当前页安装说明</Button>
        </template>
        <Alert v-else type="error" show-icon>抱歉，当前平台暂无可用APP</Alert>
      </template>
      <Alert v-else type="info" closable>请点击上方图标选择需要安装的平台</Alert>
    </template>
  </div>
</template>

<script>
import env from '@/env/env'
import axios from 'axios'

const QRCode = require('qrcode')

function execCopy (txt) {
  if (document.execCommand) {
    const textarea = document.createElement('textarea')
    document.body.appendChild(textarea)
    textarea.value = txt
    textarea.style.opacity = 0
    textarea.select()
    document.execCommand('copy')
    textarea.remove()
    return true
  } else {
    return false
  }
}

function copy (txt) {
  return new Promise(resolve => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(txt).then(
        () => resolve(true),
        () => resolve(execCopy(txt))
      )
    } else {
      resolve(execCopy(txt))
    }
  })
}


export default {
  data () {
    return {
      data: null,
      platform: null,
      error: {
        show: false,
        title: '',
        desc: ''
      },
      qrcode: {
        android: null,
        ios: null,
        activation: null
      }
    }
  },
  methods: {
    async loadData () {
      this.error.show = false
      try {
        const res = await axios.get('https://apps.e-forward.cn/api/app/release/' + env.app.merchantId + '/' + env.app.appId)
        if (res.status !== 200) {
          throw new Error(res.data && res.data.msg ? res.data.msg : res.data)
        }
        if (res.data.code !== 1) {
          throw new Error(res.data.msg)
        }
        ['android', 'ios'].forEach(async platform => {
          if (res.data.data[platform]) {
            this.qrcode[platform] = await QRCode.toDataURL(res.data.data[platform].file)
          }
        });
        QRCode.toDataURL(res.data.data.merchant.activation).then(qrcode => {
          this.qrcode.activation = qrcode
        })
        this.data = res.data.data
      } catch (e) {
        this.error.title = '系统错误'
        this.error.desc = e.message
        this.error.show = true
      }
    },
    print () {
      window.print()
    },
    async copy (txt) {
      const res = await copy(txt)
      if (res) {
        this.$Message.success('已复制')
      } else {
        this.$Message.error('复制失败，请手动复制')
      }
    }
  },
  mounted () {
    this.loadData()
  },
}
</script>
